<!--
 * @Author: Vinota
 * @Description: 公共头部
-->
<template>
    <header class="layout-header">
        <div class="head-left">
            <div class="logo">
                <img src="~@/assets/img/login/logo.png" />
                <h1>银河集团-香港身份智能申请系统</h1>
            </div>
            <div />
            <div class="head-menu">
                <div v-if="$store.state.stepEnd == 1 && $store.state.isList == 0" class="head-menu-con">
                    <router-link to="/HandleProgress">首页</router-link>
                    <router-link to="/CommonInfo">基础信息</router-link>
                    <router-link to="/WritInfo">文书信息</router-link>
                    <router-link to="/UploadData">上传资料</router-link>
                    <router-link to="/Manuscript">文书稿件</router-link>
                    <router-link to="/PostFile">邮寄原件</router-link>
                    <div v-if="type === '优才'" class="down-follow">
                        <el-dropdown placement="bottom" class="el-dropdown">
                            <span class="el-dropdown-link">
                                下载和关注<i class="el-icon-arrow-down el-icon--right"/>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="menu">
                                <el-dropdown-item class="el-dropdown-item">
                                    <div class="ite"><img class="yhicon" src="~@/assets/img/xcx_icon.png">智汇银河小程序</div>
                                    <div class="sun-qr">
                                        <img src="~@/assets/img/sun_qr_detail.png" alt="小程序太阳码" />
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item class="el-dropdown-item">
                                    <div class="ite"><img class="yhicon" src="~@/assets/img/wechat.png">智汇银河Service公众号</div>
                                    <div class="sun-qr gzh">
                                        <img src="~@/assets/img/gzh_detail.png" alt="公众号二维码" />
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="head-right">
            <div class="user-block">
                <el-dropdown trigger="click">
                    <div class="user-wrapper">
                        <div class="user-img">
                            <img src="~@/assets/img/head.png" />
                        </div>
                        <span class="user-name">{{ $store.state.user.userName }}</span>
                        <i class="el-icon-arrow-down el-icon--right" />
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-if="!($route.name === 'Manuscript' && Object.values($route.query).length === 3)"
                            command="personal" @click.native="seeProgress"
                        >选择产品</el-dropdown-item>
                        <el-dropdown-item command="loginout" @click.native="loginout">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </header>
</template>

<script lang="javascript">
import router from "@/router/index";
import { mapState } from 'vuex'
export default {
    name: "Header",
    components: {},
    data() {
        return {
            envs: process.env.VUE_APP_ENV === 'production',
        };
    },
    computed: {
        ...mapState(['type'])
    },
    created() {
        this.$store.commit("user");
        this.$store.commit("setType");
        /**
         * session 数据
         * 列表 hasFinishOnePro  0-未开始 1-进行中
         * 步骤 step  项目7步骤 当前第几步
         * 当前订单类型 YhorderType 优才/专才/留学
         */
    },
    methods: {
        //退出清除token
        loginout() {
            this.$confirm("确认退出登录?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showClose: false,
            })
                .then(() => {
                    localStorage.clear();
                    router.push({
                        path: "/Login",
                    });
                })
                .catch(() => {});
        },
        //查看项目列表
        seeProgress() {
            router.push({
                path: "/ProjectList",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.layout-header {
    width: 100%;
    min-width: 1366px;
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 100;
    background: #ffffff;
    border-bottom: 1px solid #f0f2f5;

    .head-left {
        width: calc(100% - 460px);
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding-left: 9px;
        box-sizing: border-box;
        .logo {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }

            h1 {
                font-size: 16px;
                color: #303133;
                font-weight: 600;
                margin-bottom: 3px;
                letter-spacing: 1px;
            }
        }

        .head-menu {
            margin-left: 50px;
            height: 48px;
            line-height: 48px;
            a {
                display: inline-block;
                padding: 0 16px;
                font-size: 14px;
                transition: background 0.3s ease-in;
                color: #909399;

                &:hover,
                &.router-link-active {
                    color: #409eff;
                }
            }
        }

        .nav-btns {
            display: flex;
            align-items: center;
            margin-left: 80px;

            .center-btn {
                width: 146px;
                height: 44px;
                background: rgb(255, 217, 0);
                background-size: 146px 44px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #fff;
                margin-top: 22px;

                &:first-child {
                    margin-left: -10px;
                }
            }
        }
    }

    .head-right {
        width: 460px;
        height: 48px;
        padding: 0 25px 0 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;

        .user-block {
            display: flex;
            align-items: center;
            margin: 0 24px;

            .user-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;

                .user-img {
                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 12px;
                    }
                }

                .user-name {
                    font-size: 14px;
                    color: #909399;
                    margin: 0 2px 0 5px;
                }
            }
        }
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #909399;
    }
    .el-icon-arrow-down {
        font-size: 14px;
    }
    .head-menu-con {
        display: flex;
        /deep/.el-dropdown {
            top: 0px;
            padding: 0 16px;
            &:hover {
                .el-dropdown-link {
                    color: #409eff !important;
                }
            }
        }
        /deep/.el-dropdown-link {
            font-size: 14px;
        }
    }
}

.menu {
    position: absolute;
    top: 40px !important;
    padding: 10px;
    .el-dropdown-item {
        color: #0F1214;
        padding: 10px;
        .ite {
            display: flex;
            justify-content: flex-start;
            .yhicon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        .sun-qr {
            display: none;
        }
        &:hover {
            border-radius: 4px;
            background: #F6F7FA;
            color: #0F1214;
        }
    }
    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
        position: relative;
        .sun-qr {
            width: 140px;
            height: 140px;
            display: block;
            position: absolute;
            top: -10px;
            right: -155px;
            border-radius: 6px;
            border: 1px solid rgba(130, 131, 149, 0.20);
            background: #FFF;
            box-shadow: 0px 3px 18px 0px rgba(15, 62, 174, 0.10);
            img {
                width: 136px;
                height: 136px;
            }
        }
        .gzh {
            top: -60px;
            right: -155px;
        }
    }
}

/deep/.el-dropdown {
    top: 8px;
}
</style>
