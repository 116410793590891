<template>
    <el-container>
        <el-header v-if="isShow" height="64px">
            <Header />
        </el-header>
        <el-main>
            <router-view />
        </el-main>
        <Footer v-if="isShow" />
    </el-container>

</template>

<script>
import Footer from "../../components/Footer/Footer.vue";
import Header from "../../components/Header/Header.vue";
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
           isShow: true
        }
    },
    created() {
        // 判断是否是移动设备，如不是则重定向到pc路由
        if (this.$route.query.userId && this.$route.query.orderId && this.$route.query.sign) {
            if (this.$utils.isMobile()) {
                if (this.$route.name === 'Manuscript' || this.$route.name === 'MobileManuscript') {
                    this.isShow = false
                    if (this.$route.name === 'Manuscript') {
                        this.$router.push({
                            path: '/Manuscript',//'/MobileManuscript',
                            query: this.$route.query
                        })
                    }
                }
                if (this.$route.name === 'ProjectList' || this.$route.name === 'MobileProjectList') {
                    this.isShow = false
                    if (this.$route.name === 'ProjectList') {
                        this.$router.push({
                            path: '/MobileProjectList',
                            query: this.$route.query
                        })
                    }
                }
            } else {
                if (this.$route.name === 'MobileManuscript') {
                    this.isShow = true
                    this.$router.push({
                        path: '/Manuscript',
                        query: this.$route.query
                    })
                }

                if (this.$route.name === 'MobileProjectList') {
                    this.isShow = true
                    this.$router.push({
                        path: '/ProjectList',
                        query: this.$route.query
                    })
                }
            }
        }
    }
};
</script>

<style lang="less" scoped>
    .el-main{
        padding: 0px;
    }

    .el-container{
        min-height: 100vh;
    }
</style>
